import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="navbar"
export default class extends Controller {
  static outlets = ["sidebar"];

  showSidebar() {
    this.sidebarOutlet.show();
  }
}
