import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = {
    url: String,
    param: String,
  };

  handleInputChanged(event) {
    const url = new URL(this.urlValue, window.location.origin);
    if (event.target.value !== "") {
      url.searchParams.append(this.paramValue, event.target.value);
    }
    const path = url.pathname + url.search;

    get(path, {
      responseKind: "turbo-stream",
    });
  }
}
