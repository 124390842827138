import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  install(event) {
    event.preventDefault();

    if (this.inPwa()) {
      alert("Aplikacja jest już zainstalowana.");
      return;
    }

    const pwaInstall = document.getElementsByTagName("pwa-install")[0];
    pwaInstall.showDialog();
  }

  inPwa() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }
}
