import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (this.cookiesAllowed()) {
      this.hidePopup();
    } else {
      this.showPopup();
    }
  }

  cookiesAllowed() {
    return localStorage.getItem("allow_cookies") === "true";
  }

  allowCookies() {
    localStorage.setItem("allow_cookies", "true");
    this.hidePopup();
  }

  showPopup() {
    this.element.classList.remove("hidden");
    this.element.classList.add("flex");
  }

  hidePopup() {
    this.element.classList.add("hidden");
    this.element.classList.remove("flex");
  }
}
