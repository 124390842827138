import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (this.#isFirstHistoryEntry) {
      this.element.classList.add("hidden");
    }
  }

  goBack(event) {
    if (this.#shouldRestore) {
      event.preventDefault();
      window.history.back();
    }
  }

  get #shouldRestore() {
    return !this.#isFirstHistoryEntry;
  }

  get #isFirstHistoryEntry() {
    return (
      !window.history.state.turbo ||
      window.history.state.turbo.restorationIndex === 0
    );
  }
}
