import { Turbo } from "@hotwired/turbo-rails";

function confirmMethod(message) {
  const dialog = document.getElementById("turbo-confirm");
  const body = document.body;

  dialog.querySelector("[id='dialog-body']").innerHTML = message;

  body.classList.add("overflow-hidden");
  dialog.showModal();

  return new Promise((resolve) => {
    dialog.addEventListener(
      "close",
      () => {
        dialog.returnValue === "confirm"
          ? resolve(true)
          : body.classList.remove("overflow-hidden");
      },
      { once: true },
    );
  });
}

Turbo.config.forms.confirm = confirmMethod;
