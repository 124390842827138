import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

export default class extends Controller {
  initialize() {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 300);
  }

  submit() {
    this.element.requestSubmit();
  }

  debouncedSubmit() {
    this.submit();
  }

  sanitizeFormData(event) {
    const formData = event.formData;
    for (const [name, value] of Array.from(formData.entries())) {
      if (value === "") formData.delete(name);
    }
    return formData;
  }

  clearSearch(event) {
    if (event.target.value === "") {
      this.submit();
    }
  }
}
