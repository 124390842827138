import { Controller } from "@hotwired/stimulus";
import currency from "currency.js";

export default class extends Controller {
  static targets = [
    "quantity",
    "unitPrice",
    "netAmount",
    "vatRate",
    "vatAmount",
    "grossAmount",
    "totalNetAmount",
    "totalVatAmount",
    "totalGrossAmount",
    "destroy",
  ];

  connect() {
    this.currencyOptions = { separator: " ", decimal: ",", symbol: "PLN" };
    this.formatOptions = { symbol: "" };
  }

  recalculate() {
    this.calculateItemAmounts();
    this.calculateTotalAmounts();
  }

  calculateItemAmounts() {
    this.quantityTargets.forEach((quantityTarget, index) => {
      const destroy = this.destroyTargets[index].value;
      const quantity =
        destroy === "true" ? 0 : parseInt(quantityTarget.value) || 0;
      const unitPrice = currency(
        this.unitPriceTargets[index].value,
        this.currencyOptions,
      );
      const vatRate = parseInt(this.vatRateTargets[index].value) || 0;

      const netAmount = unitPrice.multiply(quantity);
      const vatAmount = netAmount.multiply(vatRate).divide(100);
      const grossAmount = netAmount.add(vatAmount);

      this.netAmountTargets[index].textContent = netAmount.format(
        this.formatOptions,
      );
      this.vatAmountTargets[index].textContent = vatAmount.format(
        this.formatOptions,
      );
      this.grossAmountTargets[index].textContent = grossAmount.format(
        this.formatOptions,
      );
    });
  }

  calculateTotalAmounts() {
    let totalNetAmount = currency(0, this.currencyOptions);
    let totalVatAmount = currency(0, this.currencyOptions);
    let totalGrossAmount = currency(0, this.currencyOptions);

    this.netAmountTargets.forEach((netAmountTarget, index) => {
      const netAmount = currency(
        netAmountTarget.textContent,
        this.currencyOptions,
      );
      const vatAmount = currency(
        this.vatAmountTargets[index].textContent,
        this.currencyOptions,
      );
      const grossAmount = currency(
        this.grossAmountTargets[index].textContent,
        this.currencyOptions,
      );

      totalNetAmount = totalNetAmount.add(netAmount);
      totalVatAmount = totalVatAmount.add(vatAmount);
      totalGrossAmount = totalGrossAmount.add(grossAmount);
    });

    this.totalNetAmountTarget.textContent = totalNetAmount.format(
      this.formatOptions,
    );
    this.totalVatAmountTarget.textContent = totalVatAmount.format(
      this.formatOptions,
    );
    this.totalGrossAmountTarget.textContent = totalGrossAmount.format(
      this.formatOptions,
    );
  }

  quantityTargetDisconnected() {
    this.recalculate();
  }
}
