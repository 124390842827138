import { Controller } from "@hotwired/stimulus";
import currency from "currency.js";

export default class extends Controller {
  static targets = ["unitPrice", "vatRate", "unitGrossPrice"];

  connect() {
    this.currencyOptions = { separator: " ", decimal: ",", symbol: "PLN" };
    this.formatOptions = { symbol: "" };
  }

  recalculate() {
    const unitPrice = currency(
      this.unitPriceTarget.value,
      this.currencyOptions,
    );
    const vatRate = parseInt(this.vatRateTarget.value) || 0;

    this.unitGrossPriceTarget.textContent = unitPrice
      .multiply(vatRate + 100)
      .divide(100)
      .format(this.formatOptions);
  }
}
