import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ["wrapper1", "wrapper2", "wrapper3"];

  close() {
    leave(this.wrapper1Target);
    leave(this.wrapper2Target);
    leave(this.wrapper3Target);
  }

  show() {
    enter(this.wrapper1Target);
    enter(this.wrapper2Target);
    enter(this.wrapper3Target);
  }
}
